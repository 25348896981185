<template>
    <div v-if="dataSource && dataSourceType === 'IncomingDocumentDSP'"
        :key="document.id"
        class="p-relative"
    >

        <Toolbar 
            :menu="dataSource.Data.Menu"
            v-on:toolbar-button-click="onToolbarClick"
        />

        <DocumentPanel :document-info="dataSource.Data.Object" />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>
        
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>
                            
                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <!--Блок Сведения о регистрации-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("Сведения_о_регистрации") }}</div>

                                        <!--Поле Номенклатура -->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Номенклатура") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : $t("Не_указано")}}
                                                </div>
                                                <template v-else>
                                                    <div class="icon-click-row-group">
                                                        <div @click="onNomenclatureSelect">
                                                            <v-icon small v-tooltip.left-center="selectNomenclatureTooltip">
                                                                fas fa-edit
                                                            </v-icon>
                                                        </div>
                                                        <v-textarea
                                                            class="oneRowStartTextarea"
                                                            rows="1"
                                                            auto-grow
                                                            row-height="16"
                                                            no-resize
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            readonly
                                                            :value="document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : ''"
                                                            :rules="requiredRule"
                                                        >
                                                        </v-textarea>
                                                    </div>
                                                </template>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Номер регистрации, дата-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Номер_регистрации,_дата") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ concatedRegistrationData  }}
                                                </div>
                                                <div v-else class="more-inputs-in-row">

                                                    <v-text-field
                                                        :value="document.Card.RegNumber"
                                                        @input="update({ property: 'Data.Object.Document.Card.RegNumber', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        :rules="requiredRule"
                                                    >
                                                    </v-text-field>
                                                    <span>{{ $t("от_время_редактирование") }}</span>

                                                    <v-menu 
                                                        v-model="regDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="documentRegDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                :rules="requiredRule"
                                                                class="datepick-input"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="documentRegDate"
                                                            @input="regDateMenu = false"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            :min="actualIncomingDate ? actualIncomingDate : documentOutDate"
                                                            :max="serverDate"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>

                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Фактическая дата поступления-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Фактическая_дата_поступления") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ formatDate(document.Card.ActualIncomingDate) }}
                                                </div>
                                                <v-menu
                                                    v-else
                                                    v-model="actualIncomingDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="actualIncomingDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            :rules="requiredRule"
                                                            class="datepick-input"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="actualIncomingDate"
                                                        @input="actualIncomingDateMenu = false"
                                                        color="teal"
                                                        :min="documentOutDate"
                                                        :max="serverDate"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Блок Реквизиты документа-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("Реквизиты_документа") }}</div>

                                        <!--поле Тип_документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.DocumentType ? document.Card.DocumentType.Value : $t("не_указано") }}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.DocumentType"
                                                    @input="update({ property: 'Data.Object.Document.Card.DocumentType', value: $event })"
                                                    :items="documentTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Характер вопроса-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Характер_вопроса") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.QuestCharacter ? document.Card.QuestCharacter.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.QuestCharacter"
                                                    @input="update({ property: 'Data.Object.Document.Card.QuestCharacter', value: $event })"
                                                    :items="questCharacterItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="questCharacterSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Язык документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Язык_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.Languages ? documentLanguages.map((x) => x.Value).join(', ') : $t("Не_указано") }}
                                                </div>

                                                <v-autocomplete
                                                    v-else
                                                    v-model="documentLanguages"
                                                    :items="languageItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    multiple
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="multipleRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>

                                            </v-col>
                                        </v-row>

                                        <!--поле Тип носителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_носителя") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.CarrierType ? document.Card.CarrierType.Value : $t("Не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Способ доставки-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Способ_доставки") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.DeliveryType ? document.Card.DeliveryType.Value : $t("Не_указано") }}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.DeliveryType"
                                                    @input="update({ property: 'Data.Object.Document.Card.DeliveryType', value: $event })"
                                                    :items="deliveryTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Количество ЛПЭ (для пунктов не нужно)-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Количество_листов/приложений") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ `${document.Card.ListCount}/${document.Card.AttachCount}` }}
                                                </div>
                                                <div class="more-inputs-in-row" v-else>
                                                    <v-text-field
                                                        :value="document.Card.ListCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.ListCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerNotNull"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                    <span>/</span>
                                                    <v-text-field
                                                        :value="document.Card.AttachCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.AttachCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerRule.concat(requiredRule)"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>
                            
                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <!--Блок Корреспондент-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("Корреспондент") }}</div>

                                        <!--поле Отправитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Отправитель")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div v-if="!isViewMode" @click="onCorrespondentSelect">
                                                        <v-icon small v-tooltip.left-center="correspondentSelectTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData">
                                                        <v-enterprise-chip v-if="getCorrespondentName" 
                                                            :id="document.Card.CorrespondentId"
                                                            :name="getCorrespondentName" 
                                                            :canDelete="!isViewMode"
                                                            :delFunc="onCorrecpondentDelete"
                                                        />
                                                        <div v-else class="chipAreaNoData d-flex align-center pl-1">
                                                            <v-select-members-chip
                                                                v-if="!isViewMode"
                                                                target-type="Member"
                                                                @on-select="onCorrespondentSelectByChip"
                                                                :items-params="correspondentSelectParam"
                                                                :required="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Исходящий номер, дата-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Исходящий_номер,_дата") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ concatedOutRegistrationData  }}
                                                </div>
                                                <div v-else class="more-inputs-in-row">

                                                    <v-text-field
                                                        :value="document.Card.OutNumber"
                                                        @input="update({ property: 'Data.Object.Document.Card.OutNumber', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        :rules="requiredRule"
                                                    >
                                                    </v-text-field>

                                                    <span>{{ $t("от_время_редактирование") }}</span>

                                                    <v-menu 
                                                        v-model="outDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="documentOutDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                :rules="requiredRule"
                                                                class="datepick-input"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="documentOutDate"
                                                            @input="outDateMenu = false"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            :max="serverDate"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Исполнитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Исполнитель") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.ExecuterName ? document.Card.ExecuterName : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.ExecuterName"
                                                    @input="update({ property: 'Data.Object.Document.Card.ExecuterName', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Телефон исполнителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Телефон_исполнителя") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.Phone ? document.Card.Phone : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.Phone"
                                                    @input="update({ property: 'Data.Object.Document.Card.Phone', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Подписал-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Подписал")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.SignerName ? document.Card.SignerName : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.SignerName"
                                                    @input="update({ property: 'Data.Object.Document.Card.SignerName', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Обертка для блока Кем_создано-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Кем_создано") }}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Сотрудник") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-employee-chip :id="document.AuthorId" :name="document.AuthorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Дата_создания") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ formatDate(document.CreateDate) }}</div>
                                            </v-col>
                                        </v-row>
                                    
                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                                
                                <!--Блок Краткое содержание-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("Краткое_содержание") }}</div>

                                        <!--Поле Краткое содержание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{ $t("Краткое_содержание") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                 <!--Блок На исполнении-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("На_исполнении") }}</div>

                                        <!--Поле Кем поручено-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Кем_поручено") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div v-if="!isViewMode" @click="onTaskAuthorsSelect">
                                                        <v-icon small v-tooltip.left-center="selectTaskAuthorsTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData more-per-lab-wrap">
                                                        <template v-if="document.Card.TaskAuthors && document.Card.TaskAuthors.length > 0">
                                                            <template v-for="recipient in document.Card.TaskAuthors">
                                                                <v-workplace-chip
                                                                    :key="recipient.Id"
                                                                    :id="recipient.Id"
                                                                    :name="recipient.Name"
                                                                    :canDelete="!isViewMode"
                                                                    :delFunc="onTaskAuthorsDelete"
                                                                />
                                                            </template>
                                                        </template>
                                                        <template v-else-if="isViewMode">
                                                            {{ $t("Не_указано") }}
                                                        </template>
                                                        <v-select-members-chip
                                                            v-if="!isViewMode && document.Card.TaskAuthors.length == 0"
                                                            target-type="Member"
                                                            @on-select="onTaskAuthorsSelectByChip"
                                                            :items-params="taskAuthorSelectParam"
                                                        />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Текст_поручения-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Текст_поручения") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.TaskText ? document.Card.TaskText : $t("Не_указано") }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Card.TaskText"
                                                    @input="update({ property: 'Data.Object.Document.Card.TaskText', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    outlined
                                                    dense
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Кому поручено-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Кому_поручено") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div v-if="!isViewMode" @click="onTaskExecutersSelect">
                                                        <v-icon small v-tooltip.left-center="selectTaskExecutersTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div v-if="document.Card.TaskExecuters && document.Card.TaskExecuters.length > 0" class="onlyReadData more-per-lab-wrap">
                                                        <template v-for="recipient in document.Card.TaskExecuters">
                                                            <v-enterprise-chip
                                                                v-if="recipient.ContractorType == 0"
                                                                :key="recipient.Id"
                                                                :id="recipient.Id"
                                                                :name="recipient.Name"
                                                                :canDelete="!isViewMode"
                                                                :delFunc="onDeleteTaskExecuter"
                                                            />
                                                            <v-workplace-chip
                                                                v-else
                                                                :key="recipient.Id"
                                                                :id="recipient.Id"
                                                                :name="recipient.Name"
                                                                :canDelete="!isViewMode"
                                                                :delFunc="onDeleteTaskExecuter"
                                                            />
                                                        </template>
                                                        <v-select-members-chip v-if="!isViewMode"
                                                            target-type="Member"
                                                            @on-select="onTaskExecutersSelectByChip"
                                                            :items-params="taskExecutersSelectParam"
                                                        />
                                                    </div>
                                                    <div v-else-if="isViewMode" class="onlyReadData">
                                                        {{ $t("Не_указано") }}
                                                    </div>
                                                    <v-select-members-chip v-else
                                                        target-type="Member"
                                                        @on-select="onTaskExecutersSelectByChip"
                                                        :items-params="taskExecutersSelectParam"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Дата поручения-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Дата_поручения") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ formatDate(document.Card.TaskDate) }}
                                                </div>
                                                <v-menu
                                                    v-else
                                                    v-model="taskDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="cardTaskDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="cardTaskDate"
                                                        @input="taskDateMenu = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                        :min="documentRegDate"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Срок поручения-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Срок_исполнения") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ formatDate(document.Card.TaskTerm) }}
                                                </div>
                                                <v-menu
                                                    v-else
                                                    v-model="taskTermMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="cardTaskTerm | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="cardTaskTerm"
                                                        @input="taskTermMenu = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                        :min="cardTaskDate"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Текст_исполнения-->
                                        <v-row no-gutters v-if="!document.is_new_record">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Текст_исполнения") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.TaskExecutionText ? document.Card.TaskExecutionText : $t("Не_указано") }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Card.TaskExecutionText"
                                                    @input="update({ property: 'Data.Object.Document.Card.TaskExecutionText', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    outlined
                                                    dense
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper" v-if="document.Card.ControlDate">

                                <!--Блок Контроль-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{ $t("Контроль") }}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_контроля") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ $t(GetControlTypes[document.Card.ControlType]) }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Контрольный_срок") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ formatDate(document.Card.ControlDate) }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("На_контроль_поставил") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-employee-chip :id="document.Card.TakeControlEmployeeId" :name="document.Card.TakeControlEmployeeName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("C_контроля_снял") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-employee-chip v-if="document.Card.TakeOffControlEmployeeId" :id="document.Card.TakeOffControlEmployeeId" :name="document.Card.TakeOffControlEmployeeName" />
                                                    <span v-else>{{ $t("Не_указано") }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Дата_постановки_на_контроль") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ formatDate(document.Card.TakeControlDate) }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Дата_снятия_с_контроля") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.TakeOffDate ? formatDate(document.Card.TakeOffDate) : $t("не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>
                            
                        </v-row>
                    </v-form>
                </template>

                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import i18n from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import sys from '@/services/system';

import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';

export default {
    name: "IncomingDSPCard",
    components: {
        Toolbar,
        DocumentPanel
    },
    asyncComputed: {
        documentTypeItems: {
            async get() {
                return Array.from(await this.$store.dispatch('references/getReference', { id: 1001 }));
            },
            default: []
        },
        questCharacterItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('references/getReference', { id: 1003 }));

                if (this.questCharacterSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.questCharacterSearch) || i.id == this.document?.Card?.QuestCharacter?.id);
                else
                    return items;
            },
            default: [],
            watch: ['questCharacterSearch']
        },
        languageItems: {
            async get() {
                return Array.from(await this.$store.dispatch('references/getReference', { id: 1000 }));
            },
            default: []
        },
        deliveryTypeItems: {
            async get() {
                return Array.from(await this.$store.dispatch('references/getReference', { id: 1018 })).filter(x => x.id != 3);
            },
            default: []
        },
        serverDate: {
            async get() {
                return this.$moment(await this.$store.dispatch('references/getTimestamp')).format('YYYY-MM-DD');
            },
            default: null
        }
    },
    computed: {
        ...mapGetters('references', ['GetControlTypes']),
        ...mapGetters('actionsource',
        {
            dataSource: 'getDataSource',
            dataSourceType: 'getDataSourceType'
        }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            integerNotNull: 'getIntegerNotNullRule'
        }),
        isViewMode() {
            return this.dataSource.Data.FormId == "0201101";
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        isRegistered() {
            return this.document?.RegState == 1 || this.document?.RegState == 4;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);
            return pages;
        },
        documentLanguages: {
            get: function() {
                return this.document.Card.Languages?.data_list ?? [];
            },
            set: function(newValue) {
                if (this.document.Card.Languages != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages.data_list', value: newValue });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages', value: { data_list: newValue } });
            }
        },
        getCorrespondentName() {
            if (this.document?.Card?.Correspondent && this.document?.Card?.CorrespondentId === this.document.Card.Correspondent.id)
                return this.document.Card.Correspondent.Name;
            else
                return this.correspondentName;
        },
        concatedOutRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document?.Card?.OutNumber?.length)
                result = `№ ${this.document.Card.OutNumber}`;

            if (this.document?.Card?.OutDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.OutDate)} г.`;

            return result;
        },
        concatedRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document?.Card?.RegNumber?.length)
                result = `№ ${this.document.Card.RegNumber}`;

            if (this.document?.Card?.RegDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.RegDate)} г.`;

            return result;
        },
        //#region Подсказки
        correspondentSelectTooltip() {
            return i18n.t("Выберите_организацию");
        },
        selectNomenclatureTooltip(){
            return i18n.t("Выбрать_номенклатуру");
        },
        selectTaskAuthorsTooltip(){
            return i18n.t("Выбрать_авторов_поручения");
        },
        selectTaskExecutersTooltip(){
            return i18n.t("Выбрать_исполнителей_поручения");
        },
        //#endregion
        //#region Даты
        documentOutDate: {
            get: function () {
                if (this.document?.Card?.OutDate)
                    return this.$moment(this.document.Card.OutDate).format('YYYY-MM-DD');
                
                return this.document?.Card?.OutDate;
            },
            set: function(newValue) {

                if (this.$moment(this.actualIncomingDate).isBefore(this.$moment(newValue)) )
                    this.updateSource({ property: 'Data.Object.Document.Card.ActualIncomingDate', value: null });

                if (this.$moment(this.documentRegDate).isBefore(this.$moment(newValue)) )
                    this.updateSource({ property: 'Data.Object.Document.Card.RegDate', value: null });
                    
                this.updateSource({ property: 'Data.Object.Document.Card.OutDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        actualIncomingDate: {
            get: function () {
                if (this.document?.Card?.ActualIncomingDate)
                    return this.$moment(this.document.Card.ActualIncomingDate).format('YYYY-MM-DD');
                
                return this.document?.Card?.ActualIncomingDate;
            },
            set: function(newValue) {

                if (this.$moment(this.documentRegDate).isBefore(this.$moment(newValue)) )
                    this.updateSource({ property: 'Data.Object.Document.Card.RegDate', value: null });

                this.updateSource({ property: 'Data.Object.Document.Card.ActualIncomingDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        documentRegDate: {
            get: function () {
                if (this.document?.Card?.RegDate)
                    return this.$moment(this.document.Card.RegDate).format('YYYY-MM-DD');
                
                return this.document?.Card?.RegDate;
            },
            set: function(newValue) {

                this.updateSource({ property: 'Data.Object.Document.Card.RegDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        cardTaskDate: {
            get: function () {
                if (this.document?.Card?.TaskDate)
                    return this.$moment(this.document.Card.TaskDate).format('YYYY-MM-DD');
                
                return this.document?.Card?.TaskDate;
            },
            set: function(newValue) {

                this.updateSource({ property: 'Data.Object.Document.Card.TaskDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        cardTaskTerm: {
            get: function () {
                if (this.document?.Card?.TaskTerm)
                    return this.$moment(this.document.Card.TaskTerm).format('YYYY-MM-DD');
                
                return this.document?.Card?.TaskTerm;
            },
            set: function(newValue) {

                this.updateSource({ property: 'Data.Object.Document.Card.TaskTerm', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        //#endregion
        correspondentSelectParam() {
            return { 
                title: i18n.t("Выбор_отправителя"),
                includeOuterEnterprises: true,
                includeJuridical: true,
                multiple: false,
                selected: [ this.document.Card.CorrespondentId ]
            }
        },
        taskAuthorSelectParam() {
            return {
                title: i18n.t("Выбор_сотрудников"),
                includeInner: true,
                multiple: false,
                selected: this.document?.Card?.TaskAuthors?.map(x => x.Id) ?? [],
                implicitExclude: this.document?.Card?.TaskExecuters?.map(x => x.Id) ?? []
            }
        },
        taskExecutersSelectParam(){
            return {
                title: i18n.t("Выбор_исполнителей"),
                includeInner: true,
                includeOuter: true,
                includeOuterEnterprises: true,
                multiple: true,
                selected: this.document?.Card?.TaskExecuters?.map(x => x.Id) ?? [],
                implicitExclude: this.document?.Card?.TaskAuthors?.map(x => x.Id) ?? []
            }
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            tab: null,
            /** Переменная для поиска по справочнику Характер вопроса */
            questCharacterSearch: "",
            /** Переменная для выбора корреспондента */
            correspondentName: null,
            /** Переменная для выбора даты исходящего в режиме редактирования */
            outDateMenu: false,
            /** Переменная для выбора даты фактического поступления в режиме редактирования */
            actualIncomingDateMenu: false,
            /** Переменная для выбора даты регистрации в режиме редактирования */
            regDateMenu: false,
            /** Переменная для выбора даты поручения в режиме редактирования */
            taskDateMenu: false,
            /** Переменная для выбора срока поручения в режиме редактирования */
            taskTermMenu: false,
        }
    },
    methods: {
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        formatDate (source) {
            if (!source)
                return i18n.t("Не_указано");

            return this.$moment(source).format('DD.MM.YYYY');
        },
        //обработка клика по вкладкам документа
        onTabClicked (formId) {
            if (formId === this.document?.id) return;

            let component = this.$refs[formId]?.[0];

            if (component)
                if (typeof component.update === 'function')
                    component.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form[0].validate();

                    if (validate_result && this.getCorrespondentName) {
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }
                    else {
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        async onCorrespondentSelect() {
            try {
                let selectMemberParams =  this.correspondentSelectParam;
                let member = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.updateSource({ property: 'Data.Object.Document.Card.CorrespondentId', value: member?.enterprise });
                this.correspondentName = member?.name;
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onCorrespondentSelectByChip(e){
            this.updateSource({ property: 'Data.Object.Document.Card.CorrespondentId', value: e.value.enterprise });
            this.correspondentName = e.value.name;
        },
        async onCorrecpondentDelete(){
            this.updateSource({ property: 'Data.Object.Document.Card.CorrespondentId', value: null });
            this.correspondentName = null;
        },
        async onNomenclatureSelect (){
            try {
                let selected = await this.$store.dispatch('dialogs/selectNomenclature/open', null);
                this.updateSource({ property: 'Data.Object.Document.Nomenclature', value: { Code: selected[1], Header: selected[2] } });
                this.updateSource({ property: 'Data.Object.Document.NomenclatureId', value: selected[0] });
            }
            catch (ex) {
                console.log(ex.message);
            }
        },
        async onTaskAuthorsSelect() {
            try {
                let selectMemberParams = this.taskAuthorSelectParam;
                let author = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                if (author)
                    this.taskAuthorSet(author);
                else 
                    this.onTaskAuthorsDelete();
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onTaskAuthorsSelectByChip(e){
            this.taskAuthorSet(e.value);
        },
        async taskAuthorSet(value){
            this.updateSource({ property: 'Data.Object.Document.Card.TaskAuthors', value: [{ Id: value.workplaceId, Name: value.name, IsEmployee: true, ContractorType: 1 }] });
        },
        async onTaskAuthorsDelete(){
            this.updateSource({ property: 'Data.Object.Document.Card.TaskAuthors', value: [] });
        },
        async onTaskExecutersSelect() {
            try {
                let selectMemberParams = this.taskExecutersSelectParam; 
                let members = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.setTaskExecuters(members);
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onTaskExecutersSelectByChip(e){
            let value = [ ...this.document.Card.TaskExecuters, { ContractorType: e.value.type, Id: e.value.type === 0 ? e.value.enterprise : e.value.workplaceId, IsEmployee: e.value.type !== 0, Name: e.value.name  }];
            this.updateSource({ property: 'Data.Object.Document.Card.TaskExecuters', value });
        },
        async setTaskExecuters(members){
            this.updateSource({ 
                property: 'Data.Object.Document.Card.TaskExecuters',
                value: members.map( x =>
                    ({
                        Id: x.type === 0 ? x.enterprise : x.workplaceId,
                        Name: x.name,
                        IsEmployee: x.type !== 0,
                        ContractorType: x.type
                    })
                )
            });
        },
        async onDeleteTaskExecuter(id){
            let tmp = Array.from(this.document.Card.TaskExecuters);
            let itemToRemove = tmp.find(x=>x.Id == id);
            if (itemToRemove) {
                let index = tmp.indexOf(itemToRemove);
                if (index > -1) {
                    tmp.splice(index, 1);
                    this.updateSource({ property: 'Data.Object.Document.Card.TaskExecuters', value: tmp });
                }
            }
        }
    },
    async created() {
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>